export const environment: any = {
  "production": false,
  "API": "https://api.dev.aaa-idp.com/api/",
  "client_url": "https://my.dev.govworks.com",
  "visas": false,
  "header": {
    "logo": "https://govworks-email-assets.s3.amazonaws.com/aaa_idp_checkout_logo.svg"
  },
  "support": {
    "label": "Returning Customer?",
    "img": "https://govworks-email-assets.s3.amazonaws.com/idp_example%402x.jpg"
  },
  "login": {
    "enabled": false,
    "logo":"https://govworks-email-assets.s3.amazonaws.com/aaa_idp_checkout_logo.svg"
  },
  "fedex_logo": false,
  "terms": {
    "website_url": "https://www.rushmypassport.com",
    "website": "www.rushmypassport.com",
    "website_name": "RushMyPassport.com",
    "website_email": "info@rushmypassport.com"
  },
  "main_product": {
    "citizenship": "US",
    "residence_country": "US",
    "country": "US",
    "type": "idp"
  },
  "source": {
    "domain": "idp",
    "main_website": "",
    "promo_code": false,
    "name": "AAA",
    "international": true
  },
  "step-1": {
    "main": "Provide Driver's Information",
    "secondary": "Planning to rent a car and venturing out into the world on your own? Plan to obtain an International Driving Permit before you go."
  },
  "step-2": {
    "secondary": "After placing your order, proceed to our intuitive platform to fill out and submit your application."
  },
  "slides": {
    "idp": [    {
      "title": "Place an Order",
      "icon": {
        "name": "gw-slider-mouse",
        "width": "45px",
        "height": "88px"
      },
      "elements": [
        "Choose the service and the shipping option that best suits your needs and place an order.",
        "After placing an order, you will be directed to create a govWorks account to complete your IDP forms.",
        "Don't worry if you can't do it now, the application will be available for you at any time."
      ]
    },
    {
      "title": "Complete Online Wizard",
      "icon": {
        "name": "gw-slider-papers",
        "width": "69px",
        "height": "92px"
      },
      "elements": [
        "The easy-to-use wizard will guide you through the application questions.",
        "You will be able to take a passport type photo as well as pictures of your driver's license.",
        "Your answers and photos will be used to complete your IDP application."
      ]
    },
    {
      "title": "E-sign Your Application",      
      "icon": {
        "name": "gw-slider-esign",
        "width": "90px",
        "height": "90px"
      },
      "elements": [
        "Review and E-sign your application from the comfort of your home.",
        "Your application will be submitted electronically for processing.",
        "Please allow 3 business days for processing."
      ]
    }]
  },
  "footer": {
    "logo": "https://govworks-email-assets.s3.amazonaws.com/govworks.svg",
    "logo_link": "/",
    "copyright": "AAA"
  },
  "landing_pages": {
    "idp": {
      "variation": 3,
      "enabled": true,
      "title": "AAA | International Driving Permits",
      "compact_signin": true, 
      "signin": "Track your order.",
      "cta_label": "Apply Online",
      "bg": "https://govworks-email-assets.s3.amazonaws.com/idp_background%402x.jpg",
      "heading": "International Driving Permits (IDP)",
      "description": "<span style=\"font-size: 18px;\">Planning to rent a car and venture out into the world on your own? Obtain an IDP before you go. Your International Driving Permit speaks the language - even if you don't!</span><br/><span style='font-size: 0.8em'>AAA is the only entity in the U.S. authorized by the U.S. Department of State to issue an IDP.</span>"
    },
    "root_type": "idp"
  },
  "create_lead": true, 
  "summary_cta_icon": false,
  "locations_page": {
    "enabled": false
  },
  "seals": {},
  "tags": {
  }
};